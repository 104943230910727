<template>
  <div>
    <div class="topButton">
      <CRow>
        <CCol sm="4">
          <div class="titleFont floatLeft">單據管理</div>
        </CCol>
        <CCol sm="8">
          <CButton
            variant="outline"
            class=" floatRight rbutton"
            color="primary"
            @click="billModal = true"
          >
            產生明細
          </CButton>

          <v-select
            class="floatRight selectCompany2 mtop20"
            v-model="selected"
            :options="selectOptions"
            :filterable="true"
          >
          </v-select>
        </CCol>
      </CRow>
    </div>
    <div class="clearBoth"></div>
    <CCard>
      <CCardBody>
        <div class="positionR">
          <div class="tableTitle w100">
            <div
              role="group"
              class="projectSelect form-group"
              style=" width: 300px;"
            >
              <!-- <label for="uid-ksovz61x97" class=""> 月份 </label> -->
              <select
                id="yearMonth"
                class="form-control billMonth"
                v-model="selectDaily"
              >
                <option disabled value="0">帳單月份:</option>
                <option
                  :data-key="key"
                  v-for="(value, key) in SelectSixMonth"
                  :key="key"
                >
                  {{ value.name }}
                </option>
              </select>
            </div>
            <div class="tableButton" v-if="checkModel != ''">
              <CButton
                color="success"
                variant="outline"
                square
                size="sm"
                class="ml-1"
                @click="closeAC()"
              >
                關帳
              </CButton>
            </div>
          </div>
        </div>
        <CDataTable
          :items="exchangeRate"
          :fields="fields"
          :sorter="{ external: true }"
          @update:sorter-value="handlerSorterValueChange"
          hover
          :noItemsView="{ noItems: '目前無單據資料' }"
        >
          <template #select-header>
            <CInputCheckbox
              style="padding-left: 2rem"
              @update:checked="(e) => checkAll(e)"
              custom
              v-model="checkedAll"
              :checked="selectAll"
            />
          </template>
          <template #select="{ item }">
            <td>
              <template>
                <input
                  type="checkbox"
                  class="checkItem"
                  :value="item.id"
                  v-model="checkModel"
                />
                <label class="checkLabel">關帳</label>
              </template>
            </td>
          </template>
          <template #edit="{item, index}">
            <td class="py-2">
              <CButton
                color="dark"
                variant="outline"
                square
                size="sm"
                class="ml-1"
              >
                下載
              </CButton>
              <CButton
                color="danger"
                variant="outline"
                square
                size="sm"
                class="ml-1"
              >
                刪除
              </CButton>
            </td>
          </template>
        </CDataTable>

        <div v-if="page != undefined">
          <CPagination
            :activePage.sync="page.current_page"
            :pages="page.last_page"
            align="end"
            @click.native="get(page.current_page)"
            v-if="page != ''"
          />
        </div>
      </CCardBody>
    </CCard>
    <div class="popMask " v-if="billModal">
      <div class="pop_Modal ask_pop">
        <div class="popCon">
          <div class="close" @click="billModal = false">
            <img src="../../assets/img/x.png" />
          </div>
          <div class="popAll">
            <div class="title">產生費用明細</div>
            <CCard>
              <CCardBody>
                <ValidationObserver tag="form" ref="form">
                  <CForm>
                    <div role="group" class="col-sm-7 form-group">
                      <label for="uid-2yvkqocxfk3" class=""> 下層公司 </label>
                      <select
                        id="uid-2yvkqocxfk3"
                        class="form-control"
                        v-model="company"
                      >
                        <option value="0">
                          請選擇
                        </option>
                        <option
                          :data-key="value.id"
                          :value="value.id"
                          v-for="(value, key) in companySelect"
                          :key="key"
                        >
                          {{ value.name }}
                        </option>
                      </select>
                    </div>
                    <div role="group" class="col-sm-7 form-group">
                      <label for="uid-2yvkqocxfk3" class=""> 帳單月份 </label>
                      <select
                        id="uid-2yvkqocxfk3"
                        class="form-control"
                        v-model="month"
                      >
                        <option value="0">
                          請選擇
                        </option>
                        <option
                          :data-key="value.id"
                          :value="value.id"
                          v-for="(value, key) in monthSelect"
                          :key="key"
                        >
                          {{ value.month }}
                        </option>
                      </select>
                    </div>
                    <div role="group" class="col-sm-7 form-group">
                      <label for="uid-2yvkqocxfk3" class=""> 幣別 </label>
                      <select
                        id="uid-2yvkqocxfk3"
                        class="form-control"
                        v-model="currency"
                      >
                        <option value="0">
                          請選擇
                        </option>
                        <option
                          :data-key="value.id"
                          :value="value.id"
                          v-for="(value, key) in currencySelect"
                          :key="key"
                        >
                          {{ value.currency }}
                        </option>
                      </select>
                    </div>

                    <validation-provider
                      rules="required"
                      v-slot="{ errors }"
                      name="匯率"
                    >
                      <CInput label="匯率" placeholder="" class="col-sm-8" />
                      <div class="errMsg">
                        <span>{{ errors[0] }}</span>
                      </div>
                    </validation-provider>

                    <validation-provider
                      rules="required"
                      v-slot="{ errors }"
                      name="調整費率"
                    >
                      <CInput
                        label="調整費率"
                        placeholder=""
                        class="col-sm-8"
                      />
                      <div class="errMsg">
                        <span>{{ errors[0] }}</span>
                      </div>
                    </validation-provider>

                    <validation-provider
                      rules="required"
                      v-slot="{ errors }"
                      name="服務費率"
                    >
                      <CInput
                        label="服務費率"
                        placeholder=""
                        class="col-sm-8"
                      />
                      <div class="errMsg">
                        <span>{{ errors[0] }}</span>
                      </div>
                    </validation-provider>

                    <validation-provider
                      rules="required"
                      v-slot="{ errors }"
                      name="營業稅率"
                    >
                      <CInput
                        label="營業稅率"
                        placeholder=""
                        class="col-sm-8"
                      />
                      <div class="errMsg">
                        <span>{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </CForm>
                </ValidationObserver>
              </CCardBody>
            </CCard>
            <div class="form-group form-actions positionR">
              <div class="sendButton">
                <div class="button floatRight"></div>
                <div class="button floatRight">
                  <CButton
                    block
                    color="dark"
                    variant="outline"
                    @click="sendBtn()"
                    >產生
                  </CButton>
                </div>

                <div class="clearBoth"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 共用錯誤彈窗 -->
    <Modal></Modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import Modal from '../Modal.vue'
export default {
  name: 'Clients',
  //   props: ['itemTable', 'fieldsTable'],
  data() {
    return {
      details: [],
      collapseDuration: 0,
      userAuth: sessionStorage.getItem('userAuth'),
      search: '',
      orderBy: '',
      sortedBy: '',
      selectDaily: 0,
      exchangeRate: [
        {
          id: 'ba054613',
          ba_id: 'ba202005492',
          name: 'ECV',
          parent: 'Dynacloud',
          month: '202205',
          type: '費用明細',
          currency: '美金',
          exchangeRate: '-',
        },
        {
          id: 'ba0947532',
          ba_id: 'ba202005495',
          name: 'CompanyA',
          parent: 'ECV',
          month: '202205',
          type: '費用明細',
          currency: '台幣',
          exchangeRate: '0.2846246',
        },
        {
          id: 'ba0495132',
          ba_id: 'ba202005324',
          name: 'CompanyB',
          parent: 'ECV',
          month: '202205',
          type: '費用明細',
          currency: '美金',
          exchangeRate: '-',
        },
      ],
      page: { current_page: 1, last_page: 1 },
      SelectSixMonth: [
        { id: 1, name: '202205' },
        { id: 1, name: '202204' },
        { id: 1, name: '202203' },
      ],
      selected: '',
      checkedAll: false,
      checkModel: [],
      checked: false,
      selectAll: false,
      billModal: false,
      company: 0,
      companySelect: [
        { id: 1, name: 'CompanyA' },
        { id: 2, name: 'CompanyB' },
        { id: 3, name: 'CompanyC' },
        { id: 4, name: 'CompanyD' },
      ],
      month: 0,
      monthSelect: [
        { id: 1, month: '202205' },
        { id: 2, month: '202204' },
        { id: 3, month: '202203' },
      ],
      currency: 0,
      currencySelect: [
        { id: 1, currency: '美金' },
        { id: 2, currency: '台幣' },
        { id: 3, currency: '港幣' },
      ],
    }
  },
  components: {
    Modal,
  },
  computed: {
    ...mapGetters([
      'get_companies',
      'get_companiesPage',
      'get_inStock',
      'get_companiesAll',
    ]),
    fields() {
      return [
        {
          key: 'select',
          label: '',
          _style: 'min-width:80px',
          sorter: false,
          filter: false,
        },
        { key: 'id', label: '專案代碼' },
        { key: 'ba_id', label: 'BA ID' },
        { key: 'name', label: '公司名稱' },
        { key: 'parent', label: '上層公司' },
        { key: 'month', label: '帳單月份' },
        { key: 'type', label: '類型' },
        { key: 'currency', label: '幣別' },
        { key: 'exchangeRate', label: '匯率' },
        {
          key: 'edit',
          label: '',
          _style: 'width:20%',
          sorter: false,
          filter: false,
        },
      ]
    },
    // 公司下拉
    selectOptions() {
      if (this.company) {
        return this.company.map((g) => ({
          label: g.name,
          id: g.id,
        }))
      }
    },
    closeAll() {
      if (Array.isArray(this.exchangeRate)) {
        var newArr = this.exchangeRate.filter((u) => u).length
        if (newArr > 0) {
          return newArr
        } else {
          return newArr
        }
      }
    },
  },
  watch: {
    selectOptions() {
      $('.vs__search').attr('value', '請選擇')
    },
    // checkBox
    checkModel: {
      handler() {
        console.log(
          'checkModel',
          this.checkModel,
          this.checkModel.length,
          this.closeAll
        )
        sessionStorage.setItem('closeAC', this.checkModel)
        if (this.checkModel.length == this.closeAll && this.checkModel != '') {
          this.selectAll = true
        } else {
          this.selectAll = false
        }
      },
      deep: true,
    },
    checkedAll() {
      console.log('checked all ', this.checkedAll)
    },
  },
  methods: {
    handlerSorterValueChange(o) {
      this.orderBy = o.column
      this.sortedBy = o.asc == true ? 'ASC' : 'DESC'
      let data = {
        orderBy: this.orderBy,
        sortedBy: this.sortedBy,
        page: 1,
      }
      this.$store.dispatch('actionCompanies', data)
    },
    createCompany() {
      this.$router.push('/company/create')
    },
    view(item) {
      this.$router.push('/company/view/' + item.id)
    },
    edit(item) {
      this.$router.push('/exchangeRate/edit/' + item.id)
    },
    users(item) {
      this.$router.push('/provider/client_user/' + item.id)
    },
    get(page) {
      let data = {
        orderBy: this.orderBy,
        sortedBy: this.sortedBy,
        page: page,
      }
      this.$store.dispatch('actionCompanies', data)
    },
    Contract(item) {
      this.$router.push('/provider/company/contract/' + item.id)
    },
    getCompany() {
      let data = {
        companyName: this.search,
        orderBy: this.orderBy,
        sortedBy: this.sortedBy,
        page: 1,
      }
      this.$store.dispatch('actionCompanies', data)
    },
    // 關帳全選
    checkAll(checked) {
      if (checked == true) {
        this.checkModel = []

        var list = this.exchangeRate.filter((u) => u)
        list.forEach((item) => {
          this.checkModel.push(item.id)
        })

        this.checked = true
      } else {
        this.checkModel = []
        this.checked = false
      }
      //   console.log('選擇', this.checkModel)
    },
    // 關帳
    async closeAC() {
      let closeAC = sessionStorage.getItem('closeAC')
      if (closeAC) {
        console.log('送出關帳')
        // let data = {
        //   statements: this.checkModel,
        // }
        // sessionStorage.setItem('recloseCompany', this.company)
        // await this.$store.dispatch('actionCheckClose', data)
        // sessionStorage.removeItem('closeAC')
      } else {
        console.log('未勾選關帳資料')
      }
    },
  },
  created() {
    // this.orderBy = 'created_at'
    // this.sortedBy = 'DESC'
    // let data = {
    //   orderBy: this.orderBy,
    //   sortedBy: this.sortedBy,
    // }
    // this.$store.dispatch('actionCompanies', data)
    // let dataCompany = {
    //   orderBy: '',
    //   sortedBy: 'DESC',
    // }
    // this.$store.dispatch('actionCompaniesAll', dataCompany)
  },
}
</script>
